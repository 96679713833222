import {
  PlusOutlined,
  ProfileOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import {
  App,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Popover,
  Row,
  Select,
} from 'antd';
import { ConnectWalletPopover } from 'components/ConnectWalletPopover';
import { SUPPORTED_PLATFORMS } from 'constants/multisender';
import { Routes } from 'constants/routs';
import { SECOND } from 'constants/time';
import dayjs from 'dayjs';
import { useEVMMultisend } from 'libs/evm/hooks/useMultisend';
import { useAccount } from 'libs/hooks/useAccount';
import { useTRONMultisend } from 'libs/tron/hooks/useMultisend';
import { NetworkType } from 'libs/types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BuilderViewVariant,
  multisenderStore,
  MultisenderTabs,
  MultisenderVariant,
  useMultisenderStore,
} from 'screens/Multisender/store';
import { multisenderService } from 'services/multisender';
import { transactionService } from 'services/transactionService';
import { walletService } from 'services/walletService';
import { useCurrencyStore } from 'store/currencies';
import { useSettingsStore } from 'store/settings';
import { MultisenderKind, MultisenderState } from 'types/multisender';
import { toCapital } from 'utilities/string';
import { RawContractErrorType } from 'viem';
import { useShallow } from 'zustand/react/shallow';
import styles from '../../MultisenderView.module.css';
import {
  DEFAULT_NETWORK,
  initialRecipients,
  MultisenderForm,
} from '../../form';
import { RecipientsTable } from '../RecipientsTable';
import { FooterSummary } from './FooterSummary';
import { TemplateTools } from './TemplateTools';
import { MultisenderWizard } from './Wizard';

interface BuilderViewProps {
  variant?: BuilderViewVariant;
}

export function BuilderView({
  variant = BuilderViewVariant.Default,
}: BuilderViewProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSavingAsTemplate, setIsSavingAsTemplate] = useState(false);
  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const chains = useSettingsStore((s) => s.settings.chains);
  const [currencies, isCurrenciesLoading] = useCurrencyStore(
    useShallow((s) => [
      s.currencies.filter((c) =>
        SUPPORTED_PLATFORMS.includes(c.platform.chain)
      ),
      s.isLoading,
    ])
  );
  const [form] = Form.useForm<MultisenderForm>();
  const { notification } = App.useApp();
  const [initialBuilderValues, setInitialBuilderValues, setTab] =
    useMultisenderStore((s) => [
      s.initialBuilderValues,
      s.setInitialBuilderValues,
      s.setTab,
    ]);

  const { sendToMany: sentToManyTron } = useTRONMultisend();
  const { sendToMany: sentToManyEVM } = useEVMMultisend();

  const { address, isConnected, networkType } = useAccount();
  const [isTxCompleted, setIsTxCompleted] = useState(false);

  const navigate = useNavigate();

  const networkCode = useMemo(() => {
    switch (variant) {
      case BuilderViewVariant.Default:
        return networkType;
      case BuilderViewVariant.Template:
        return initialBuilderValues?.network;
    }
  }, [networkType, variant, initialBuilderValues?.network]);

  const initialName = useMemo(() => {
    const date = dayjs().format('DD-MMMM-YYYY HH:mm');
    return `Batch ${date}`;
  }, []);

  useEffect(() => {
    walletService.getWallets();
    transactionService.getTags();
    transactionService.getCurrencies();
  }, []);

  const handleReset = () => {
    const date = dayjs().format('DD-MMMM-YYYY HH:mm');
    setInitialBuilderValues(undefined);
    form.setFieldsValue({
      name: `Batch ${date}`,
      recipients: initialRecipients,
      token: currencies?.[0]?.id,
    });
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    const recipients = values.recipients.filter(
      (recipient) =>
        recipient?.counterparty ||
        recipient?.walletAddress?.[0] ||
        recipient?.amount
    );

    try {
      const token = currencies?.find((c) => c?.id === values.token);
      if (networkType !== token?.network_type) {
        notification.error({
          message: 'Wallet address doesn’t match ',
          description:
            'Selected Source wallet doesn’t match with Token type',
          placement: 'bottom',
        });
        return
      }
      await form.validateFields();
      if (recipients.length <= 0) {
        throw new Error('Recipients list is empty');
      }
    } catch (error) {
      handleSubmitFailed();
      return;
    }

    setIsSubmitting(true);

    let txId;
    let platform: string | undefined;

    const { multisenderVariant } = multisenderStore.getState();
    const isSequential = multisenderVariant === MultisenderVariant.Sequential;

    try {
      switch (networkType) {
        case NetworkType.Tron: {
          const selectedToken = currencies?.find((c) => c?.id === values.token);
          platform = DEFAULT_NETWORK;

          const tokenAddress = selectedToken?.platform?.contract_address;

          const decimals = selectedToken?.platform?.decimals ?? 6;

          if (!tokenAddress) {
            throw new Error('Token address is not found');
          }
          txId = await sentToManyTron({
            token: tokenAddress,
            decimals,
            recipients: recipients,
            multiSendParams: {
              name: values.name,
              currency_id: selectedToken?.tokenId,
              platform,
            },
            kind: isSequential ? MultisenderKind.Sequential : undefined,
          });
          break;
        }
        case NetworkType.EVM: {
          const selectedToken = currencies?.find((c) => c?.id === values.token);
          platform = selectedToken?.platform.chain || 'ethereum';

          const decimals = selectedToken?.platform?.decimals ?? 6;

          if (!selectedToken?.platform.contract_address) {
            throw new Error('Token address is not found');
          }

          txId = await sentToManyEVM({
            token: selectedToken,
            decimals,
            recipients: recipients,
            multiSendParams: {
              name: values.name,
              currency_id: selectedToken?.tokenId,
              platform,
            },
            kind: isSequential ? MultisenderKind.Sequential : undefined,
          });
          break;
        }
        default:
          throw new Error('Network not supported');
      }

      const explorerTransactionUrl = chains.find(
        (n) => n.code === platform
      )?.explorer_transaction_url;

      if (!isSequential) {
        notification.success({
          message: 'Multisend completed',
          description: (
            <>
              <a
                href={`${explorerTransactionUrl}/${txId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View TX Info
              </a>{' '}
              or{' '}
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    !window.location.pathname.startsWith(Routes.Multisender)
                  ) {
                    navigate(Routes.Multisender);
                  }
                  setTimeout(() => {
                    setTab(MultisenderTabs.History);
                  }, 0);
                }}
              >
                Open history
              </Button>
            </>
          ),
          placement: 'bottom',
          duration: 30,
        });
      }

      setIsTxCompleted(true);
      setTimeout(() => {
        setIsTxCompleted(false);
        handleReset();
      }, 5 * SECOND);
    } catch (error) {
      let parsedError;
      try {
        parsedError = JSON.parse(JSON.stringify(error as Error, null, 2));
      } catch (error) {}
      if (
        (parsedError &&
          (error as Error).message
            ?.toLowerCase()
            ?.includes('rejected the request')) ||
        (error as any)?.message?.toLowerCase()?.includes('declined by user')
      ) {
        return;
      }

      if ((error as any).error === 'CONTRACT_VALIDATE_ERROR') {
        notification.error({
          message: 'Error',
          description: (error as any).message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        if (networkType === NetworkType.EVM) {
          const parsedError = error as RawContractErrorType;
          notification.error({
            message: 'Error',
            description:
              parsedError?.shortMessage ||
              parsedError?.details ||
              parsedError.message,
            placement: 'bottom',
          });
          return;
        }
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitFailed = () => {
    notification.error({
      message: 'Complete data',
      description: 'Fill all fields and complete transactions list to proceed',
      placement: 'bottom',
    });
  };

  const handleSaveTemplate = async () => {
    const values = form.getFieldsValue();

    const recipients = values.recipients.filter(
      (recipient) =>
        recipient.counterparty ||
        recipient.counterparty ||
        recipient.walletAddress?.[0] ||
        recipient.amount
    );

    try {
      await form.validateFields();
      if (recipients.length <= 0) {
        throw new Error('Recipients list is empty');
      }
    } catch (error) {
      handleSubmitFailed();
      return;
    }
    setIsSavingAsTemplate(true);

    try {
      await multisenderService.createMultisend({
        name: values.name,
        description: values.description ?? '',
        currency_id: values.token,
        chain: networkType === NetworkType.EVM ? 'arbitrum' : 'tron',
        state: MultisenderState.Template,
        source: address ?? '',
        targets: recipients.map((el) => ({
          address: el.walletAddress?.[0] ?? '',
          amount: el.amount ?? 0,
        })),
      } as any);

      notification.success({
        message: 'Template saved',
        description: `${values.name} saved as a template`,
        placement: 'bottom',
      });
    } catch (error) {
      notification.error({
        message: 'Template save error',
        description: 'Try again later',
        placement: 'bottom',
      });
    } finally {
      setIsSavingAsTemplate(false);
    }
  };

  return (
    <Form<MultisenderForm>
      form={form}
      initialValues={{
        token: `74-tron`,
        ...initialBuilderValues,
        name: initialName,
        recipients: initialBuilderValues?.recipients || initialRecipients,
        network: networkCode === NetworkType.EVM ? 'arbitrum' : 'tron',
      }}
      rootClassName={styles.form}
      requiredMark={(label, rules) => {
        return (
          <>
            {label}
            {rules.required && <span className={styles.required}>*</span>}
          </>
        );
      }}
      labelCol={{ span: 24, className: styles.label }}
      disabled={isSubmitting}
    >
      <Row gutter={24} className={styles.formHeader}>
        <Col span={6}>
          <Form.Item<MultisenderForm>
            label="Name"
            name="name"
            className={styles.formItem}
            rules={[{ required: true, message: '' }]}
          >
            <Input size="large" autoFocus />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item<MultisenderForm>
            label="Token"
            name="token"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select
              placeholder="Select token"
              size="large"
              loading={isCurrenciesLoading}
              options={currencies.map((currency) => ({
                label: (
                  <>
                    {currency.symbol.toUpperCase()}{' '}
                    <span className="selectItemHint">
                      {toCapital(currency.platform.chain)}
                    </span>
                  </>
                ),
                key: currency.id,
                value: currency.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item<MultisenderForm>
            label="Rows"
            className={styles.formItem}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.recipients.length !== currentValues.recipients.length
            }
          >
            {({ getFieldValue }) => {
              const recipients = getFieldValue('recipients');
              return <Input size="large" readOnly value={recipients.length} />;
            }}
          </Form.Item>
        </Col>
        {variant === BuilderViewVariant.Default && (
          <Col span={11}>
            <Flex gap={20}>
              <Form.Item<MultisenderForm>
                label="Source Wallet"
                className={styles.formItem}
                required
                rules={[{ required: true, message: 'Wallet is required' }]}
              >
                {isConnected && (
                  <Flex gap={12} align="center">
                    {address}
                    <Popover
                      content={
                        <ConnectWalletPopover
                          isOpen={isConnectOpen}
                          onOpenChange={setIsConnectOpen}
                        />
                      }
                      trigger="click"
                      title="Connect Wallet"
                      placement="bottom"
                      open={isConnectOpen}
                      onOpenChange={setIsConnectOpen}
                    >
                      <Button
                        type="link"
                        size="small"
                        icon={<RetweetOutlined />}
                      >
                        Replace wallet
                      </Button>
                    </Popover>
                  </Flex>
                )}
                {!isConnected && (
                  <Popover
                    content={
                      <ConnectWalletPopover
                        isOpen={isConnectOpen}
                        onOpenChange={setIsConnectOpen}
                      />
                    }
                    trigger="click"
                    title="Connect Wallet"
                    placement="bottom"
                    open={isConnectOpen}
                    onOpenChange={setIsConnectOpen}
                  >
                    <Button type="link" size="small" icon={<PlusOutlined />}>
                      Connect wallet
                    </Button>
                  </Popover>
                )}
              </Form.Item>
            </Flex>
          </Col>
        )}
      </Row>
      <RecipientsTable />
      <Flex vertical gap={16} className={styles.footer}>
        <FooterSummary variant={variant} />
        <Flex align="center" gap={16}>
          <Button
            onClick={handleReset}
            disabled={isSubmitting}
            htmlType="button"
          >
            Reset table
          </Button>
          {variant === BuilderViewVariant.Default && (
            <>
              <Button
                onClick={() => handleSaveTemplate()}
                disabled={isSubmitting || !address}
                loading={isSavingAsTemplate}
                htmlType="button"
                icon={<ProfileOutlined />}
              >
                Save as Template
              </Button>
              <MultisenderWizard
                isSubmitting={isSubmitting}
                isCompleted={isTxCompleted}
                onSubmit={handleSubmit}
                onError={handleSubmitFailed}
              />
            </>
          )}
          {variant === BuilderViewVariant.Template && <TemplateTools />}
        </Flex>
      </Flex>
    </Form>
  );
}
