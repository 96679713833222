import { Multisend } from 'types/multisender';
import { Address } from 'viem';
import { TRON_MULTISENDER_ABI } from './tron';
import { arbitrum, mainnet } from 'viem/chains';

export const ETHEREUM_MULTISENDER_ADDRESS: Address =
  '0x39a7d6a1a3e3431594ddfea70eec7d1c61a20c3c';
export const ARBITRUM_MULTISENDER_ADDRESS: Address =
  '0x6209632BD61606aAE8C720ac2aB29Aea164b6386';

export const EVM_MULTISENDER_ADDRESS: Record<
  Multisend['multisend']['chain'],
  Address
> = {
  mainnet: ETHEREUM_MULTISENDER_ADDRESS,
  arbitrum: ARBITRUM_MULTISENDER_ADDRESS,
};

export const EVM_MULTISENDER_CHAINS: Record<
  Multisend['multisend']['chain'],
  number
> = {
  mainnet: mainnet.id,
  arbitrum: arbitrum.id,
};

export const EVM_MULTISENDER_ABI = TRON_MULTISENDER_ABI;
