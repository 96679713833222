export enum MultisenderState {
  New = 'new',
  Processing = 'processing',
  InProgress = 'in_progress',
  Confirmed = 'confirmed',
  Failed = 'failed',
  Template = 'template',
}

export enum MultisenderKind {
  Sequential = 'sequential',
}

export enum MultisenderItemStatus {
  New = 'new',
  Processing = 'processing',
  Done = 'done',
}

export interface Multisend {
  multisend: {
    id: string;
    name: string;
    description: string | null;
    state: MultisenderState;
    chain: string;
    chain_icon_url: string;
    tx_url?: string;
    network: string;
    source: {
      address: string;
      user_wallet_id: string | null;
      user_wallet_name: string | null;
      counterparty_wallet_id: string | null;
      counterparty_wallet_name: string | null;
      counterparty_id: string | null;
      counterparty_name: string | null;
    };
    targets: {
      address: string;
      user_wallet_id: string | null;
      user_wallet_name: string | null;
      counterparty_wallet_id: string | null;
      counterparty_wallet_name: string | null;
      counterparty_id: string | null;
      counterparty_name: string | null;
      amount: string;
      status?: MultisenderItemStatus;
      tx_url?: string;
    }[];
    currency: {
      id: number;
      name: string;
      symbol: string;
      logo_url: string;
      platforms: {
        chain: string;
        contract_address: string;
        decimals: number;
      }[];
    };
    created_at: string;
    seqsender_meta: {
      id: number;
      chain_id: number;
      network_type: string;
      from: string;
      fee: number;
      token_address: string;
      recipients: {
        address: string;
        amount: number;
        tx: string;
        status: MultisenderState;
      }[];
      amount: string;
      address: string;
      transfer_tx: string | null;
      status: MultisenderState;
    } | null;
  };
}

export interface CreateMultisend {
  name: string;
  description: string;
  currency_id: number;
  chain: string;
  state: MultisenderState;
  source: string;
  tx_id?: string;
  kind?: MultisenderKind;
  targets: {
    address: string;
    amount: number;
  }[];
}

export interface GasEstimationsItem {
  approve_fee: number;
  chain: string;
  contract_interaction_fee: number;
  currency_decimal: number;
  currency_symbol: string;
  transfer_fee: number;
  created_at: string;
}

export interface GasEstimations {
  [key: string]: GasEstimationsItem;
}
