import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Segmented, Typography } from 'antd';
import clsx from 'clsx';
import { TrialBanner } from 'components/TrialBanner';
import { Routes } from 'constants/routs';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import styles from './MultisenderView.module.css';
import { BuilderView } from './components/BuilderView';
import { HistoryView } from './components/HistoryView';
import { TemplatesView } from './components/TemplatesView';
import {
  BuilderViewVariant,
  multisenderStore,
  MultisenderTabs,
  MultisenderVariant,
  useMultisenderStore,
} from './store';

interface MultisenderViewProps {
  variant?: MultisenderVariant;
}

export function MultisenderView({
  variant = MultisenderVariant.Default,
}: MultisenderViewProps) {
  const [
    tab,
    builderViewVariant,
    initialBuilderValues,
    setTab,
    setInitialBuilderValues,
    setBuilderViewVariant,
  ] = useMultisenderStore(
    useShallow((s) => [
      s.tab,
      s.builderViewVariant,
      s.initialBuilderValues,
      s.setTab,
      s.setInitialBuilderValues,
      s.setBuilderViewVariant,
    ])
  );
  const prevTab = useRef<MultisenderTabs>();

  useEffect(() => {
    const { setMultisenderVariant } = multisenderStore.getState();
    setMultisenderVariant(variant);
  }, [variant]);

  useEffect(() => {
    return () => {
      setTab(MultisenderTabs.Builder);
      setBuilderViewVariant(BuilderViewVariant.Default);
      setInitialBuilderValues(undefined);
    };
  }, []);

  useEffect(() => {
    if (prevTab.current === MultisenderTabs.Builder) {
      setInitialBuilderValues(undefined);
    }
    return () => {
      prevTab.current = tab;
    };
  }, [tab, setInitialBuilderValues]);

  return (
    <>
      <TrialBanner />

      <div className={styles.container}>
        <Flex
          justify="space-between"
          align="center"
          className={clsx(
            styles.header,
            tab === MultisenderTabs.Builder && styles.stickyHeader
          )}
        >
          <Flex align="center" gap={30}>
            <Typography.Title level={2} style={{ margin: 0 }}>
              {builderViewVariant === BuilderViewVariant.Default && (
                <>Multisender {variant === MultisenderVariant.Sequential && '(beta)'}</>
              )}
              {builderViewVariant === BuilderViewVariant.Template && (
                <>Edit: {initialBuilderValues?.name}</>
              )}
            </Typography.Title>
            {builderViewVariant === BuilderViewVariant.Default && (
              <Segmented
                options={Object.values(MultisenderTabs)}
                value={tab}
                onChange={setTab}
              />
            )}
          </Flex>
          {builderViewVariant === BuilderViewVariant.Default && (
            <Link
              to={Routes.Transactions}
              onClick={() => setInitialBuilderValues(undefined)}
            >
              <Button
                size="large"
                icon={<CloseOutlined />}
                aria-label="Close"
              />
            </Link>
          )}
        </Flex>
        {tab === MultisenderTabs.Builder && (
          <BuilderView variant={builderViewVariant} />
        )}
        {tab === MultisenderTabs.History && <HistoryView />}
        {tab === MultisenderTabs.Templates && <TemplatesView />}
      </div>
    </>
  );
}
